:root {
    /*Margins & Paddings*/
    --padding: 1rem; 
    --margin: 1rem; 
    --vertical-padding: 1rem; /* must NOT be a percentage value */
    --vertical-margin: 1rem; /* must NOT be a percentage value */
    /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: "GillSans","Lato", sans-serif;
    --alternative-font-family: var(--base-font-family);
}

@import "/fontmodules/Lato.css";
@import "/fontmodules/OpenSans.css";

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/


.kontakt .imagetop {
    display: none;
}


.slickwrapp {
    position: relative;
}

.introtext {
    position: absolute;
    color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.introtext .container{
    height: 100%;
}

.introtext .container h1{
    background: rgba(13, 4, 74, 0.56);
    padding: 20px;
    height: calc(100% - 40px);
    display: inline-flex;
    margin: 20px;
    max-width: 50%;
}




article.starttext {
    padding: 20px 0px;
    border-top: 2px solid #11034b;
    border-bottom: 2px solid #11034b;
    margin-bottom: 1em;
}


.box1 {
    background: #78A1BB;;
}
.box2 {
    background: #7A7978;
}
.box3 {
    background: #BFA89E;
}

.four-special {
    padding: 1.5em;
    flex-basis: calc((100% / 3) - (1.5em * 2 + var(--margin) * 2));
    color: #fff;
}
.four-special h2 {
    font-weight: 400;
}


/*------------------------------------------------------------------------------------------------ 
GENERAL ----------------------------------------------------------------------------------------*/

body {
    font-family: "GillSans", "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    line-height: 1.5;
    font-weight: 400;
    color: #303030;
}
p {
    margin: 3px 0 5px;
}
a, a:hover {
    text-decoration: none;
    color: #0e044b;
}
img {
    max-width: 100%;
    height: auto;
    flex: none;
    width: 100%;
}

.imagetop {
    min-height: 30vh;
    background: no-repeat center center;
    background-size: cover;
}

figure {
    margin: 0px;
}
.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

/* ------------------------------- FONTS + HEADINGS ---------------------------- */
h1, h2, h3, h4, h5, h6 {
    font-family: "GillSans", "Lato";
    margin: 0.5em 0;  
}
.slick-title {
    font-size: 1.6rem;
    font-family: "Lato";
    font-weight: 600;
    text-align:center;
}


/* ------------- WRAPPERS ------------- */
.container.sml {
    max-width: 900px;
}
.container.med {
    max-width: 1300px;
}
.container.lrg {
    max-width: 1500px;
}
.container {
    width: 100%; /*fixar issue med bredd i vissa flexelement i IE*/
}
.wrapper {
    min-height: calc(100vh - 181px);
    position: relative;
    /*margin-top: 80px; För att flytta ner innehåll vid position:fixed på header*/
}

/*------------------------------------------------------------------------------------------------ 
STARTSIDA ----------------------------------------------------------------------------------------*/

.four-special:hover {
    color: #fff !important;
    
}

/* ---------------SLIDER----------- */
.slick {
    margin-bottom: 0;

}
.slick-track, .slick-substitute {
    display: flex;
    flex-direction: row;

} 
.slick-initialized .slick-slide, .slick-substitute > div {
    height: 100%;
    min-height: 40vh;
    width:100%;
    display: flex;
    background-size: cover;
    background-position: center center;
}

/*------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
CONTENT -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------*/
/* ----------------Personal------------  */

.personalitem img {
    border-radius: 50%;
}

/* ----------------STARTTEXT------------  */


/*------------------------------------------------------------------------------------------------ 
Nyheter & Nyhet--------------------------------------------------------------------------------*/
/* -----------------NYHETER---------------- */
.newsitem .button {
    background: #303030;
    color: white;
    border: 1px solid black;
    transition: background 1s ease;
}
.newsitem .button:hover {
    background: #000;
    color: white;
    border: 1px solid black;
}

.artikelbild {
    float: right;
    max-width: 40%;
    height: auto;
    margin: 0 0 15px 15px
}

.newsitem hr {
    color: #efefef;

}

/* ----------------PAGINAION------------  */

div.pagination {
    margin: 2rem auto 0;
    padding-bottom: 1vh;
    list-style: none;
    width:100%;
    text-align: center;
}

ul.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

ul.pagination  li a {
    background: #000;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #303030;
}

/*------------------------------------------------------------------------------------------------ 
HEADER -----------------------------------------------------------------------------------------*/

/* ------------NAV + HEADER--------------- */
header {
    background: #fff;
    border-bottom: 1px solid #e4e4e4;
    z-index: 10;
    width: 100%;
    position: relative; /*om fixed el absolute, glöm ej margin-top på main*/
    top: 0;
    padding: 10px;
}

.logo {
    padding-left: 10px;
}
.logo img {
    max-height: 160px; 
    width: auto; 
}
.logo a, .logo img {
    display: block;
}

header nav {}
header nav ul.menu {}
header nav ul.menu li {}
header nav ul.menu li a {
    font-size: 1em;
    font-weight: 400;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    border-right: #eee solid 1px;
    padding: 7px 13px;
    text-transform: uppercase;
}
.menu.menu li a:hover, .menu.menu li.active a {
    color: #0d044a;
}
.menu>li {
    margin: 0px;
}
/*
nav ul.menu li.dropdown > a:after { content: "<"; display: inline-block; margin: 0 0 0 5px; }
*/

/*----------------------------MOBILMENY----------------------------*/
@media (max-width: var(--breakpoint-menu)) 
{
    header nav ul.menu.show, header nav ul.menu.menu, header nav ul.menu li a,  header nav ul.menu .dropdown ul,  ul.sub-menu-items {
        background: #fff;
    }


}






/*------------------------------------------------------------------------------------------------ 
FOOTER & KONTAKT--------------------------------------------------------------------------------*/
/* -----------------FOOTER---------------- */
footer {
    background-color: #303030;
    padding: 30px 0;
}
footer, footer a, footer a:hover { 
    color: #fff; 
}
footer svg {
    margin-right: 10px;
    fill: white;
}
footer h4 {
    margin-top:0;
}

/* -----------------Intendit-footer---------------- */
.created-by {
    background: #000;
    text-align: center;
    padding: 10px 0; 
}
p.intenditfooter, p.intenditfooter a, p.intenditfooter a:hover  {
    color: #717171;
    line-height:1.1;
}
p.intenditfooter {
    margin:0;
}
/* -----------------KONTAKT---------------- */


/* -----------------KONTAKTFORMULÄR---------------- */

.boltform form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.boltforms-row {
    flex-basis: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.boltforms-six-row {
    flex-basis: 50%;
}

.boltforms-four-row {
    flex-basis: 33%;
}

.boltforms-three-row {
    flex-basis: 25%;
}

.boltform textarea, .boltform input {
    width: 100%;
}

@media (max-width: var(--breakpoint-s))
{
    .boltforms-row {
        flex-basis: auto;
    }

    .boltform form {
        flex-direction: column;
    }
}




.formwrapper {
    background-color: #ececec; 
    padding: 20px 0px;
}

.boltforms-row button {
    margin: 0 auto;
    display: block;
    background: #10024b;
    border: none;
}

.boltforms-row button:hover {
    background: #000;

}
/* --------------- Google maps --------------------*/
/* Give map a height and it's images no max-width */
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}


/*------------------------------------------------------------------------------------------------ 
MEDIA QUERIES ----------------------------------------------------------------------------------*/
/*----------------------------Allt under 1500px----------------------------*/
@media (max-width:1500px)
{
    .container.lrg {
        width: 96%; 
    } 
}
/*----------------------------Allt under 1300px----------------------------*/

@media (max-width:1300px)
{
    .container.med {
        width: 96%; 
    } 
}

/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{

    .container {
        width: 96%; 
    } 


}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    /*Containers*/
    .container.sml {
        width: 96%; 
    } 

    /*Footer*/   
    footer .s-twelve {
        text-align: center; 
    }
    footer .s-twelve a {
        display:block; 
        text-align:center; 
        margin: 0 auto; 
    } 

    .boltforms-l-left-row {
        max-width: 100%;
    }
}
/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{ 
    .logo img {
        max-width: 100px
    }
    .introtext .container h1{
        max-width: 90%;
    }
    .introstart h1 {
        font-size: 1.5em !important;
    }
}
